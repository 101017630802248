import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register required Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const GraphComponent = ({ apiResponse }) => {
  // Validate and extract labels from DailyDetails
  const labels =
    Array.isArray(apiResponse?.DailyDetails) && apiResponse.DailyDetails.length > 0
      ? apiResponse.DailyDetails.map((detail) => {
          const match = detail.Label.match(/Year\s*=\s*(\d+),\s*Month\s*=\s*(\d+)/);
          return match ? `${match[1]}-${match[2].padStart(2, "0")}` : detail.Label;
        })
      : ["No Data"];

  // Extract datasets from DailyDetails properties
  const properties = [
    { key: "CompletedQuizzes", label: "Completed Quizzes", color: "rgba(75, 192, 192, 0.2)", borderColor: "rgba(75, 192, 192, 1)" },
    { key: "TotalCorrectAnswers", label: "Correct Answers", color: "rgba(54, 162, 235, 0.2)", borderColor: "rgba(54, 162, 235, 1)" },
    { key: "TotalWrongAnswers", label: "Wrong Answers", color: "rgba(255, 99, 132, 0.2)", borderColor: "rgba(255, 99, 132, 1)" },
    { key: "InCompleteQuizzes", label: "InCompleted Quizzes", color: "rgba(255, 206, 86, 0.2)", borderColor: "rgba(255, 206, 86, 1)" },
  ];

  const datasets = properties.map((prop) => ({
    label: prop.label,
    data: Array.isArray(apiResponse?.DailyDetails)
      ? apiResponse.DailyDetails.map((detail) => detail[prop.key] || 0)
      : [0],
    backgroundColor: prop.color,
    borderColor: prop.borderColor,
    borderWidth: 1,
  }));

  // Prepare chart data
  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time Period",
        },
      },
      y: {
        title: {
          display: true,
          text: "Counts",
        },
      },
    },
  };

  return (
    <div>
      <h2>User Activity Summary</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default GraphComponent;
