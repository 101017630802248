import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePreference } from "../context/UserContext";
import QuizzesModel from "../common/QuizzesModel";

import { Button, Modal } from "react-bootstrap";
import PayPalModal from "./paypal/PaypalModel";
import SubscribeButton from "./SubscribeButton";
import Swal from "sweetalert2";

import imageUrl from "../assets/img/aaa.png"; // Import the local image
import DescriptionComponent from "./DescriptionComponent";
import SpellingLoader2 from "../common/SpellingLoader2";
import ComingSoon from "../common/ComingSoon";
import ShowCart from "./ShowCart";
import TTSTest from "../common/TTSTest";
import AdvertisementModel from "../common/AdvertisementModel";

const NewContent = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const username = localStorage.getItem("username");
  const userRole = localStorage.getItem("userrole");
  const navigate = useNavigate();

  const {
    updateIsNavVisible,
    showCart,
    updateShowCart,
    cart,
    updateCart,
  } = usePreference();

  const updateQuizId = (value) => {
    navigate(`/playquiz/${value}`);
  };
  const addToCart = (pack, type, price, isCollection, action, packname) => {
    updateShowCart(true);

    // Ensure cart is initialized as an empty array if it's not already
    updateCart((prevCart) => {
      const currentCart = Array.isArray(prevCart) ? prevCart : [];

      if (action === "add") {
        // Check if the item (either collection or category) is already in the cart
        const itemAlreadyInCart = currentCart.some(
          (item) =>
            item.packId === pack.Id && item.isCollection === isCollection
        );

        if (itemAlreadyInCart) {
          Swal.fire({
            title: "Info",
            text: "This item is already in your cart.",
            icon: "info",
          });
          return currentCart; // Stop here if the item is already in the cart
        }

        if (isCollection) {
          if (Array.isArray(pack.Categories)) {
            const categoryAlreadyInCart = currentCart.some((item) =>
              pack.Categories.some(
                (category) => item.packId === category.CollectionId
              )
            );

            if (categoryAlreadyInCart) {
              Swal.fire({
                title: "Alert",
                text:
                  "Some categories from this collection are already in your cart. Please remove them first if you wish to add the entire collection.",
                icon: "warning",
              });
              return currentCart;
            }

            // Remove all categories that are part of this collection
            const updatedCart = currentCart.filter(
              (item) =>
                !pack.Categories.some(
                  (category) => item.packId === category.CollectionId
                )
            );
            const newCartItem = {
              pack,
              packId: pack.Id,
              type,
              price,
              isCollection,
              packname: packname,
            };

            const newCart = [...updatedCart, newCartItem];
            localStorage.setItem("cart", JSON.stringify(newCart)); // Save to localStorage
            return newCart;
          }
        } else {
          if (Array.isArray(currentCart)) {
            const collectionInCart = currentCart.some(
              (item) => item.packId === pack.CollectionId
            );

            if (collectionInCart) {
              Swal.fire({
                title: "Alert",
                text:
                  "The collection containing this category is already in your cart. You cannot add individual categories from the collection.",
                icon: "warning",
              });
              return currentCart;
            }
          }

          const newCartItem = {
            pack,
            packId: pack.Id,
            type,
            price,
            isCollection: false,
            packname: packname,
          };

          const newCart = [...currentCart, newCartItem];
          localStorage.setItem("cart", JSON.stringify(newCart)); // Save to localStorage
          return newCart;
        }
      } else if (action === "remove") {
        const updatedCart = currentCart.filter(
          (item) =>
            !(item.packId === pack.Id && item.isCollection === isCollection)
        );
        localStorage.setItem("cart", JSON.stringify(updatedCart)); // Save updated cart to localStorage
        return updatedCart;
      }
    });
  };

  useEffect(() => {
    const targetDate = new Date("2024-12-31");
    const currentDate = new Date();

    // Check if current date is before the target date
    if (currentDate < targetDate) {
      // Check login status using localStorage
      const isLoggedIn = localStorage.getItem("loggedin") === "true"; // Check if logged in

      if (!isLoggedIn) {
        // Non-logged-in users: Show modal only once per day
        // const today = new Date().toISOString().split("T")[0]; // Current date in YYYY-MM-DD format
        // const lastShownDate = localStorage.getItem("modalLastShownDate");

        setIsOpenAdd(true);
      } else {
        // Non-logged-in users: Show modal only once per day
        const today = new Date().toISOString().split("T")[0]; // Current date in YYYY-MM-DD format
        const lastShownDate = localStorage.getItem("modalLastShownDate");

        if (lastShownDate !== today) {
          // Show modal if it hasn't been shown today
          setIsOpenAdd(true);
          localStorage.setItem("modalLastShownDate", today); // Update the last shown date
        } else {
          setIsOpenAdd(false);
        }
      }
    } else {
      // alert("The current date is on or after 16th December 2024!");
      setIsOpenAdd(false);
    }
  }, []);

  useEffect(() => {
    function setBackgroundImage(imageUrl) {
      document.body.style.backgroundImage = `url(${imageUrl})`;

      document.body.style.backgroundSize = "cover"; // Ensures the image covers the entire background
      document.body.style.backgroundPosition = "center"; // Centers the image
    }
    // Example usage with a dynamic URL
    setBackgroundImage(imageUrl);
  }, []);

    useEffect(() => {
    // Update the navigation visibility state
    updateIsNavVisible(true);

    // Function to fetch Free and Paid data based on API type (User/Admin/Anonymous)
    const fetchData = async (freeApiUrl, paidApiUrl) => {
      try {
        // Fetch free packages
        const freeResponse = await axios.get(freeApiUrl);
        const freeData = freeResponse.data;

        // Set free data initially
        setData(freeData); // Set the free data
        setFilteredData(freeData); // Display free data initially

        // Fetch paid packages
        const paidResponse = await axios.get(paidApiUrl);
        const paidData = paidResponse.data;

        // Combine free and paid data
        const combinedData = [...freeData, ...paidData];

        setData(combinedData); // Set the combined data
        setFilteredData(combinedData); // Display all data
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false); // Stop loading regardless of success or error
      }
    };

    if (username && username.trim()) {
      // If username is available, check user role
      const role = userRole && userRole.trim().toLowerCase(); // Ensure userRole has value and trim spaces

      if (role === "admin" || role === "superadmin") {
        // If user is admin or superadmin, use Admin APIs
        const adminFreeApiUrl = `https://s3napi.s3nsoftware.com/api/QuizDatas/WordPacksInfo/Admin/Free?username=${username}`;
        const adminPaidApiUrl = `https://s3napi.s3nsoftware.com/api/QuizDatas/WordPacksInfo/Admin/Paid?username=${username}`;

      //fetchData(adminFreeApiUrl, adminPaidApiUrl); // Fetch Admin Free and Paid data
        fetchData(adminPaidApiUrl, adminFreeApiUrl); // Fetch Admin Free and Paid data

      } else {
        // If user is not admin/superadmin, use User APIs
        const userFreeApiUrl = `https://s3napi.s3nsoftware.com/api/QuizDatas/WordPacksInfo/User/Free?username=${username}`;
        const userPaidApiUrl = `https://s3napi.s3nsoftware.com/api/QuizDatas/WordPacksInfo/User/Paid?username=${username}`;

        //fetchData(userFreeApiUrl, userPaidApiUrl); // Fetch User Free and Paid data


        fetchData(userPaidApiUrl, userFreeApiUrl); // Fetch User Free and Paid data
      }
    } else {
      // If no username, use Anonymous APIs
      const anonymousFreeApiUrl = `https://s3napi.s3nsoftware.com/api/QuizDatas/WordPacksInfo/Anonymous/Free`;
      const anonymousPaidApiUrl = `https://s3napi.s3nsoftware.com/api/QuizDatas/WordPacksInfo/Anonymous/Paid`;

     // fetchData(anonymousFreeApiUrl, anonymousPaidApiUrl); // Fetch Anonymous Free and Paid data

      fetchData(anonymousPaidApiUrl, anonymousFreeApiUrl); // Fetch Anonymous Free and Paid data

    }
  }, [username, userRole]); // Ensure this effect runs whenever `username` or `userRole` changes


  // Function to handle search
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    if (searchValue === "") {
      // Show all data when search is cleared
      setFilteredData(data);
    } else {
      const filtered = data
        .map((packs) => {
          // Check if the main package name matches
          if (packs.Package.toLowerCase().includes(searchValue)) {
            return packs; // Return full package if it matches
          }
          // Otherwise, check if any of the categories match
          const matchedCategories = packs.Categories.filter((category) =>
            category.CategoryName.toLowerCase().includes(searchValue)
          );
          // Return only the package with the matched categories if any
          if (matchedCategories.length > 0) {
            return { ...packs, Categories: matchedCategories };
          }
          // Return null if nothing matches
          return null;
        })
        .filter(Boolean); // Remove null values
      setFilteredData(filtered); // Set the filtered data
    }
  };

  const calculatePercentage = (progress) => {
    const [total, correct] = progress.split(",").map(Number); // Split and convert to numbers
    return (correct / total) * 100; // Calculate percentage
  };

  const displayProgressText = (progress) => {
    const [total, correct, wrong] = progress.split(",").map(Number); // Split the progress into total, correct, and wrong
    return `${correct + wrong}/${total}`; // Display correct and wrong answers out of total
    //  return `${correct}/${total} correct, ${wrong}/${total} wrong`; // Display correct and wrong answers out of total
  };

  const [openQuizModel, setOpenQuizModel] = useState(false);
  const [catId, setCatId] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [isCollectionId, setIsCollectionId] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [amount, setAmount] = useState(1);
  const [filteredCart, setFilteredCart] = useState([]);
  const updateQuizModel = (value) => {
    setOpenQuizModel(value);
  };

  const [showPaypalModel, setShowPaypalModel] = useState(false); // To manage modal visibility

  const updateShowPaypalModel = (value) => {
    setShowPaypalModel(value);
  };
  // Show the PayPal modal

  useEffect(() => {
    if (cart.length === 0) {
      updateShowCart(false);
    } else {
      // Build a new array with selected fields from the cart
      const filteredCart = cart.map((item) => ({
        packId: item.packId,
        price: item.price,
        isCollection: item.isCollection,
        type: item.type === "monthly" ? true : false,
      }));

      // Do something with the new array, e.g., log it or set it to state
      console.log("Filtered Cart:", filteredCart);

      // Optionally, you can save the new array to the state if needed
      setFilteredCart(filteredCart); // Assuming you have a `setFilteredCart` state function
    }
  }, [cart]);

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    console.log(" cart data ", savedCart);
    if (savedCart) {
      updateCart(JSON.parse(savedCart)); // Load cart from localStorage
    }
  }, []);

  const [pack, setPack] = useState({});

  const handleClick = (pack) => {
    if (pack.IsLocked) {
      // Show SweetAlert when pack is locked
      Swal.fire({
        icon: "warning",
        text: "Please subscribe for access to the category",
        confirmButtonText: "OK",
      });
    } else {
      // Perform your regular actions when pack is not locked

      navigate(`/playquiz/${pack.Id}`);

      //setCatId(pack.Id);
      //setOpenQuizModel(true);

      //setPack(pack);
      // You can also navigate here if necessary
      // navigate(`/playquiz/${pack.Id}`);
      localStorage.setItem("backgroundimage", pack.CategoryImage);
    }
  };

  // useEffect(()=>{
  //   localStorage.setItem('cart', JSON.stringify([]));
  // updateCart([]);
  // },[])

  // Function to delete an item from the cart
  const deleteItem = (packId, type) => {
    updateCart((prevCart) => {
      // Filter the cart to remove the item
      const updatedCart = prevCart.filter(
        (item) => !(item.packId === packId && item.type === type)
      );

      // Save the updated cart to localStorage
      localStorage.setItem("cart", JSON.stringify(updatedCart));

      // Return the updated cart to update the state
      return updatedCart;
    });
  };

  // Toggle cart visibility
  const toggleCartVisibility = () => {
    //updateCart([]);
    updateShowCart(!showCart);
  };
  const calculateTotal = () => {
    return cart.reduce((sum, item) => sum + item.price, 0).toFixed(2);
  };

  const findCategoryName = (pack, id) => {
    // Find the package by Id
    const foundPack = filteredData.find((pac) => pac.Id === pack.CollectionId);

    // Check if the package and its Categories exist
    if (foundPack && foundPack.Categories) {
      // Find the category by Id
      const category = foundPack.Categories.find((cat) => cat.Id === id);

      // Return the CategoryName if found
      return category ? category.CategoryName : "Unknown Category";
    }

    return "Unknown Category"; // Default if package or category not found
  };

  const { updateIsLoginOpen, updateIsSignUpOpen } = usePreference();

  const handleCheckout = () => {
    const loggedin = localStorage.getItem("loggedin");
    if (loggedin == null || loggedin === "false") {
      Swal.fire({
        icon: "warning",
        text: "Please login or signup to subscribe",
        showCancelButton: true, // Show Cancel button
        showDenyButton: true, // Show Deny button
        confirmButtonText: "Login", // Confirm button text
        denyButtonText: "Sign Up", // Deny button text
        cancelButtonText: "Cancel", // Cancel button text
      }).then((result) => {
        if (result.isConfirmed) {
          // "Login" button clicked
          updateIsLoginOpen(true);
          updateIsSignUpOpen(false);
        } else if (result.isDenied) {
          // "Sign Up" button clicked
          updateIsSignUpOpen(true);
          updateIsLoginOpen(false);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // "Cancel" button clicked
          console.log("User canceled the action.");
        }
      });

      return;
    }

    // alert("Checkout")
    updateShowPaypalModel(true);
    //setAmount(1);
    setAmount(calculateTotal());
  };

  const scrollToCollection = (index, _) => {
    // Set the search term to the selected package
    setSearchTerm(_.Package);

    // Filter the data to show only the selected package and its categories
    const filtered = data
      .map((packs) => {
        // Check if the package name matches the selected one
        if (packs.Package.toLowerCase() === _.Package.toLowerCase()) {
          return packs; // Return the full package if it matches
        }
        // Otherwise, return null
        return null;
      })
      .filter(Boolean); // Remove null values

    // Set the filtered data to show only the selected package
    setFilteredData(filtered);

    // Optionally scroll to the selected collection (if you want to scroll the page to the filtered section)
  };

  const calculateDaysUntilExpiration = (dateString) => {
    // Parse the date string into a JavaScript Date object
    const expirationDate = new Date(dateString);
    const currentDate = new Date();
    // Calculate the difference in time
    const timeDifference = expirationDate - currentDate;
    // Calculate the difference in days
    const daysUntilExpiration = Math.ceil(
      timeDifference / (1000 * 60 * 60 * 24)
    ); // Convert ms to days
    // Return the number of days, ensure it's not negative
    return daysUntilExpiration > 0 ? daysUntilExpiration : 0;
  };

  function getFormattedPackageName(packageName) {
    const words = packageName
      .replace(/\s+/g, "#*#")
      .split("#*#")
      .filter(Boolean); // Split the string by spaces and remove any empty entries

    if (words.length === 1) {
      // Only one word, take the first 4 characters of the word
      return words[0].slice(0, 4);
    } else {
      // Take the first letter of the first word and enough characters from the last word to make 4 characters
      const firstChar = words[0].charAt(0); // First letter of the first word
      const lastWord = words[words.length - 1]; // Last word

      // Calculate how many characters we need from the last word to make the total length 4
      const remainingChars = lastWord.slice(0, 3); // We need 3 more characters from the last word

      // Combine the first character of the first word with the needed characters from the last word
      return firstChar + remainingChars;
    }
  }

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const updateOpenAdd = (value) => {
    setIsOpenAdd(value);
  };

  const scrollContainerRefs = useRef({}); // Object to store refs for each scrollable container

  const handleScrollLeft = (id) => {
    const container = scrollContainerRefs.current[id];
    if (container) {

      // Get the first item in the scrollable container
    const firstItem = container.querySelector(".item-align");
    if (firstItem) {
      // Get the computed width of the first item and calculate scroll distance
      const itemWidth = parseFloat(window.getComputedStyle(firstItem).width);
      const gap = parseFloat(window.getComputedStyle(container).gap) || 0; // Extract gap between items
      const scrollDistance = itemWidth + gap;

      // Perform the scroll
      container.scrollBy({
        left: -scrollDistance, // Scroll distance dynamically calculated
        behavior: "smooth",
      });
    } else {
      console.warn("No .item-align element found inside container.");
    }

      
    }else {
      console.warn("No container found with the given ID.");
    }
  };

  const handleScrollRight = (id) => {
    const container = scrollContainerRefs.current[id];
    if (container) {

      // Get the first item in the scrollable container
    const firstItem = container.querySelector(".item-align");
    if (firstItem) {
      // Get the computed width of the first item and calculate scroll distance
      const itemWidth = parseFloat(window.getComputedStyle(firstItem).width);
      const gap = parseFloat(window.getComputedStyle(container).gap) || 0; // Extract gap between items
      const scrollDistance = itemWidth + gap;

      // Perform the scroll
      container.scrollBy({
        left: scrollDistance, // Scroll distance dynamically calculated
        behavior: "smooth",
      });
    } else {
      console.warn("No .item-align element found inside container.");
    }

      
    }else {
      console.warn("No container found with the given ID.");
    }
  };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     handleScrollRight();
  //   }, 3000); // Scroll every 3 seconds
  //   return () => clearInterval(interval); // Clear interval on unmount
  // }, []);

  // const handleScrollRight = () => {
  //   scrollContainerRef.current.scrollBy({
  //     left: 300, // Adjust scroll distance as needed
  //     behavior: "smooth", // Smooth scrolling
  //   });
  // };

  return (
    <>
      <div>
        <div
          id="categories"
          style={{
            width: "90%",
            margin: "30px auto",
            height: "80vh",
            padding: "20px 40px",
            boxShadow: "0px 0px 2px",
            position: "relative", // Ensure relative positioning for the container
          }}
        >
          <ComingSoon />

          {/* Cart Section with Background Color and Close Button */}
          {showCart && (
            <ShowCart
              showCart={showCart}
              toggleCartVisibility={toggleCartVisibility}
              cart={cart}
              deleteItem={deleteItem}
              handleCheckout={handleCheckout}
              calculateTotal={calculateTotal}
              filteredCart={filteredCart}
            />
          )}

          <div
            className="first-last"
            // style={{
            //   position: "sticky", // Make the div sticky
            //   top: "0", // Stick to the top
            //   backgroundColor: "#E5EBDF#aprant", // Ensure a background to cover content behind
            //   zIndex: "100", // Ensure it's on top of other elements
            //   padding: "10px 0", // Adjust padding
            // }}
          >
            <div>
              <div>
                WORD COLLECTIONS ({data.length} items)
                {data.map((pack, index) => (
                  <span
                    key={index}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      display: "inline-block",
                      transform: "scale(1)", // Default scale
                      transition:
                        "transform 0.3s ease-in-out, background-color 0.3s ease", // Smooth transition for both scale and background color
                    }}
                    onClick={() => scrollToCollection(index, pack)} // Handle click
                    title={pack.Package}
                  >
                    {/* Render the first letter of the first 3 words, with rounded border */}
                    <span
                      onMouseEnter={(e) => {
                        e.target.style.transform = "scale(1.2)"; // Zoom in on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.transform = "scale(1)"; // Zoom out when mouse leaves
                        e.target.style.backgroundColor =
                          index % 2 === 0 ? "#f5a623" : "#4a90e2"; // Reset background color
                      }}
                      style={{
                        fontSize: "10px",
                        padding: "2px",
                        backgroundColor:
                          index % 2 === 0 ? "#f5a623" : "#4a90e2", // Alternating background colors
                        borderRadius: "50%", // Rounded shape
                        textAlign: "center",
                        color: "white", // White text color for contrast
                        width: "30px", // Set fixed width and height for the circle
                        height: "30px",
                        display: "inline-block",
                        lineHeight: "25px", // Vertical centering within the circle
                      }}
                    >
                      {getFormattedPackageName(pack.Package)}
                    </span>
                  </span>
                ))}
                <span
                  style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                    display: "inline-block",
                    padding: "5px",
                    color: "black ",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    setSearchTerm("");
                    setFilteredData(data);
                  }}
                >
                  All
                </span>
              </div>
            </div>

            <input
              type="search"
              className="search-ctrl"
              style={{
                borderRadius: "20px",
                borderStyle: "none",
                padding: "5px 10px",
                height: "40px",
              }}
              placeholder="Search category name..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <span>
            Take bite size quizzes, each one of 20 words or less, and improve
            spelling proficiency. Get instant feedback on correct/incorrect
            answers. Save progress with timer to resume later. Take incorrect
            only, resume, or clear and retake the entire quiz
          </span>

          <hr
            style={{
              marginTop: "20px", // Adjust the top margin
              borderColor: "#000", // Set the color of the horizontal line
              borderWidth: "2px", // Set the thickness of the line
              width: "100%",
            }}
          />
          {/* Loader */}
          {/* {loading && <div className="loader"> {<SpellingLoader2 />}</div>} */}

          {/* Display error if any */}
          {error && <p>{error}</p>}

          {/* Render filtered data only */}
          {!loading && filteredData.length > 0
            ? filteredData.map((packs, index) => (
                <div
                  key={packs.Id}
                  id={`collection-${index}`}
                  className="packs"
                >
                  <div className="first-last">
                    <div className="first-last">
                      <h5>
                        {" "}
                        {packs.Package} [
                        {getFormattedPackageName(packs.Package)}]
                      </h5><>({  packs.Categories.reduce((sum, item) => sum + item.WordCount, 0)
                      }W)</>
                      {packs.IsSubscribed &&
                      packs.DiscountDesc == "Subscribed" ? (
                        <>
                          <span
                            style={{
                              color: "green",
                              fontWeight: "bold",
                              cursor: "auto",
                              marginLeft: "10px",
                            }}
                          >
                            Subscribed
                          </span>
                        </>
                      ) : (
                        <>
                          {" "}
                          <span style={{ marginLeft: "10px" }}></span>
                          {packs.IsFree == true ? (
                            <span
                              style={{
                                color: "green",
                                fontWeight: "bold",
                                cursor: "auto",
                              }}
                            >
                              Always Free
                            </span>
                          ) : (
                            <>
                              <SubscribeButton
                                packs={packs}
                                buttonWidth={"12%"}
                                IsCollectionId={true}
                                addToCart={addToCart}
                                packname={packs.Package} // Pass addToCart function to the button
                              />
                            </>
                          )}
                        </>
                      )}
                      
                    </div>
                  </div>

                  <DescriptionComponent packs={packs} />

                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    
                    {/* Left Arrow */}
                    {packs.Categories.length > 0 && (

                    <button
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 2,
                        backgroundColor: "rgba(0,0,0,0.1)",
                        color: "green",
                        border: "none",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleScrollLeft(packs.Id)}
                    >
                      &#8249; {/* Left arrow symbol */}
                    </button>)}

                    {/* Scrollable Container */}
                    <div
                      ref={(ref) =>
                        (scrollContainerRefs.current[packs.Id] = ref)
                      } // Assign unique ref
                      className="category-scroll-container"
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        scrollBehavior: "smooth",
                        gap: "10px",
                        padding: "10px",
                        scrollbarWidth: "none",
                      }}
                    >
                      {packs.Categories.map((pack) => (
                        <div
                          className="item-align"
                          key={pack.Id}
                          style={{
                            flex: "0 0 auto", // Ensure each item takes up its space and prevents shrinking
                            marginTop: "20px",
                            cursor: "pointer",
                            transition: "transform 0.3s ease-in-out", // Smooth transition
                          }}
                          onClick={() => handleClick(pack)} // Handle click everywhere except SubscribeButton
                          onMouseEnter={(e) => {
                            e.currentTarget.style.transform = "scale(1.05)"; // Zoom in slightly on hover
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.transform = "scale(1)"; // Return to normal size when mouse leaves
                          }}
                        >
                          <div className="pack-details">
                            <img
                              className="pack-image"
                              src={pack.CategoryImage}
                              alt=""
                            />
                            <div className="pack-info">
                              <div className="first-last">
                                <h6
                                  className="cat-name"
                                  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {pack.CategoryName}<>({pack.WordCount}W) </>
                                </h6>

                                {/* Conditional Free/Locked content */}
                                {pack.IsFree ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Always Free
                                  </span>
                                ) : (
                                  <>
                                    {pack.IsLocked ? (
                                      // Stop click propagation inside SubscribeButton
                                      <div
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent triggering parent click
                                        }}
                                      >
                                        <SubscribeButton
                                          packs={pack}
                                          style={{ width: "50%" }}
                                          IsCollectionId={false}
                                          addToCart={addToCart} // Pass addToCart function to the button
                                          packname={pack.CategoryName} // Pass pack name to the button
                                        />
                                      </div>
                                    ) : (
                                      <span
                                        style={{
                                          color: "green",
                                          cursor: "auto",
                                        }}
                                      >
                                        Expires in{" "}
                                        {calculateDaysUntilExpiration(
                                          pack.Expired
                                        )}{" "}
                                        days
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>

                              <p
                                style={{
                                  height: "50px", // Set a fixed height
                                  overflowY: "auto", // Enable vertical scrolling
                                  whiteSpace: "pre-wrap", // Ensure the text respects line breaks and whitespaces
                                  wordWrap: "break-word", // Break long words if necessary
                                  padding: "0px", // Optional: Padding for better readability
                                  borderRadius: "5px", // Optional: Rounded corners for better appearance
                                }}
                              >
                                {pack.Discription}
                              </p>

                              {!pack.IsLocked && (
                                <div>
                                  {/* Progress bar */}
                                  <div
                                    className="progress"
                                    style={{
                                      color: "#D67534",
                                      "--bs-primary": "#D67534",
                                      "--bs-primary-rgb": "214,117,52",
                                    }}
                                  >
                                    <div
                                      className="progress-bar"
                                      aria-valuenow={
                                        pack.Progress
                                          ? calculatePercentage(pack.Progress)
                                          : 0
                                      }
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width: `${
                                          pack.Progress
                                            ? calculatePercentage(pack.Progress)
                                            : 0
                                        }%`,
                                      }}
                                    ></div>
                                    {pack.Progress
                                      ? `${displayProgressText(pack.Progress)}`
                                      : "0/0 correct, 0/0 wrong (0%)"}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Right Arrow */}
                    {packs.Categories.length > 0 && (
                    <button
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 2,
                        backgroundColor: "rgba(0,0,0,0.1)",
                        color: "green",
                        border: "none",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleScrollRight(packs.Id)}
                    >
                      &#8250; {/* Right arrow symbol */}
                    </button>)}
                  </div>
                  
                </div>
              ))
            : !loading && <p>No data found.</p>}
        </div>
      </div>

      {openQuizModel ? (
        <QuizzesModel
          open={openQuizModel}
          updateQuizModel={updateQuizModel}
          catId={catId}
          updateQuizId={updateQuizId}
          pack={pack}
        />
      ) : null}

      <PayPalModal
        showPaypalModel={showPaypalModel}
        updateShowPaypalModel={updateShowPaypalModel}
        amount={amount}
        SelectedId={12}
        IsCollectionId={true}
        IsMonthly={true}
        Username={username}
        cartdata={cart}
        filteredCart={filteredCart}
      />

      <AdvertisementModel open={isOpenAdd} updateIsOpen={updateOpenAdd} />
    </>
  );
};

export default NewContent;
