import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import FullWordModel from "./common/FullWordModel";
import Admin from "./components/admin/Admin";
import { usePreference } from "./context/UserContext";
import LoginModel from "./components/login/LoginModel";
import SignUpModel from "./components/signup/SignUpModel";
import CommentModel from "./common/CommentModel";
import Feedback from "./components/Feedback";
import AdminFeedback from "./components/AdminFeedback";
import UserProfile from "./components/EditProfile/UserProfile";

import "./assets/css/styles.css";

import NewNavbar from "./components/NewNavbar";
import NewContent from "./components/NewContent";

import NewUI from "./components/newplayer/NewUI";
import PayPalModal from "./components/paypal/PaypalModel";
import UserRankings from "./components/statistics/UserRankings";
import ForgotPasswordModal from "./components/login/ForgotPasswordModal";
import Extra from "./common/Extra";
import UserActivityTracker from "./components/statistics/UserActivityTracker";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const {
    isLoginOpen,
    updateIsLoginOpen,
    isSignUpOpen,
    updateIsSignUpOpen,
    searchedId,
    updateSearchedId,
    isForgotPwdOpen,
    upateForgotPwdOpen,
  } = usePreference();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const updateIsOpen = (i) => {
    setIsOpen(i);
  };
  const [selectedid, setSelectedId] = useState(0);
  const updateSelectedId = (s) => {
    setSelectedId(s);
    // alert(s);
  };

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const [quizId, setQuizId] = useState(0);
  const updateQuizId = (value) => {
    setQuizId(value);
  };

  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const updateShowMOdal = (value) => {
    setShowModal(value);
  };

  return (
    <>
      <div
        style={{
          fontFamily: "Ubuntu, sans-serif",
        }}
      >
        <NewNavbar setShowModal={updateShowMOdal} />
        <section id="hero">
          <Routes>
            <Route path="/" element={<NewContent />} />
            <Route path="/home" element={<NewContent />} />
            <Route path="" element={<NewContent />} />
            <Route path="/playquiz/:id" element={<NewUI />} />
            <Route path="/admin" element={<Admin />} />
            <Route
              path="/ranks"
              element={<UserRankings setShowModal={updateShowMOdal} />}
            />
            <Route path="/feedbacks" element={<AdminFeedback />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/extra" element={<Extra />} />
            <Route path="/statistics" element={<UserActivityTracker />} />
          </Routes>
        </section>
      </div>
      <FullWordModel
        open={isOpen}
        updateIsOpen={setIsOpen}
        selectedid={selectedid}
      />
      <LoginModel open={isLoginOpen} updateIsOpen={updateIsLoginOpen} />
      <SignUpModel open={isSignUpOpen} updateIsOpen={updateIsSignUpOpen} />
      <CommentModel />
      <PayPalModal />
      <ForgotPasswordModal
        IsForgotPwd={isForgotPwdOpen}
        upateForgotPwd={upateForgotPwdOpen}
      />
      <Feedback showModal={showModal} setShowModal={updateShowMOdal} />
    </>
  );
}

export default App;
